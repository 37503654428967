.hideHeader {
    embed {
        position: relative;
        
        .wp-block-template-part{
            background-color: red;
        }
    }
    height: 100%;
}

.wp-block-template-part{
    background-color: red;
}

.modal-content{
    max-width: 599px;
    margin: auto;
}

.modal-body {
    height: 80vh;
}